import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { APP_ROUTES } from './routes';
import { FileUploadModule } from 'ng2-file-upload';

/**
 * Modulo que incluye los componentes específicos y propios de la aplicación
 */
@NgModule({
  imports: [
    SharedModule,
    FileUploadModule,
    RouterModule.forRoot(APP_ROUTES, { useHash: true })
  ],
  declarations: []
})
export class RoutesModule {}
